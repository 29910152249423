@import 'variables';
@import 'mixins';

@keyframes jumpAnim {
  0% {
    transform: translate(0px, -10px);
  }
  50% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, -10px);
  }
}

@-webkit-keyframes jumpAnim {
  0% {
    -webkit-transform: translate(0px, -10px);
  }
  50% {
    -webkit-transform: translate(0px, 0px);
  }
  100% {
    -webkit-transform: translate(0px, -10px);
  }
}

@-moz-keyframes jumpAnim {
  0% {
    -moz-transform: translate(0px, -10px);
  }
  50% {
    -moz-transform: translate(0px, 0px);
  }
  100% {
    -moz-transform: translate(0px, -10px);
  }
}

@-o-keyframes jumpAnim {
  0% {
    -o-transform: translate(0px, -10px);
  }
  50% {
    -o-transform: translate(0px, 0px);
  }
  100% {
    -o-transform: translate(0px, -10px);
  }
}

@-ms-keyframes jumpAnim {
  0% {
    -ms-transform: translate(0px, -10px);
  }
  50% {
    -ms-transform: translate(0px, 0px);
  }
  100% {
    -ms-transform: translate(0px, -10px);
  }
}

header #desktop-contact-btn {
  background-color: transparent;
}

header.scroll #desktop-contact-btn {
  background-color: #243267;
}

#hero {
  font-family: 'ralewaymedium', sans-serif;
  background-image: url("/images/homepage_hero_2.jpg");
  position: relative;
  background-size: cover;

  .row {
    #title-container {
      border-left: 2px solid #fff;
      @include under576 {
        margin-top: 5rem;
      }

      h1 {
        font-size: 9rem;
        letter-spacing: 3px;
        @include under1200{
          font-size: 7rem;
          letter-spacing: 2px;
        }
        @include under768{
          font-size: 5rem;
          letter-spacing: 1px;
        }

        span {
          font-size: 9rem;
          @include under1200{
            font-size: 7rem;
          }
          @include under768{
            font-size: 5rem;
          }
        }
      }
    }
  }

  &::before{
    content: "";
    display: block;
    position: absolute;
    width: 78vw;
    height: 110vh;
    border-right: 1px solid #ded7ca;
    border-bottom: 1px solid #ded7ca;
    transform: rotateZ(-32deg);
    transform-origin: 0 0;
    left: 8%;
    top: 38%;
    z-index: 0;
    border-top-left-radius: 20rem;
  }

  #hero-inner {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    background-position: center;
    background-size: cover;
    padding-top: 11rem;
    @include under576 {
      padding-top: 1rem;
    }

    &::after{
      content: "";
      display: block;
      position: absolute;
      width: 78vw;
      height: 110vh;
      border-top: 1px solid #ded7ca;
      border-left: 1px solid #ded7ca;
      transform: rotateZ(-32deg);
      transform-origin: 0 0;
      left: 8%;
      top: 38%;
      z-index: 0;
      border-top-left-radius: 20rem;
    }
  }

  .scroll-down {
    text-align: center;
    width: 100%;
    bottom: 5rem;
    left: 0;
    position: absolute;
    z-index: 100;
    letter-spacing: 2px;
    animation: jumpAnim ease 1s;
    animation-iteration-count: infinite;
    -webkit-animation: jumpAnim ease 1s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation: jumpAnim ease 1s;
    -moz-animation-iteration-count: infinite;
    -o-animation: jumpAnim ease 1s;
    -o-animation-iteration-count: infinite;
    -ms-animation: jumpAnim ease 1s;
    -ms-animation-iteration-count: infinite;

    a {
      text-transform: uppercase;
      font-size: 1.5rem;
      color: #fff;
      text-decoration: none;
    }

    img {
      display: inline-block;
      margin-right: 1.5rem;
    }
  }
}

#programs {
  font-family: 'ralewaymedium', sans-serif;
  position: relative;
  z-index: 1;
  overflow: hidden;

  .line-title {
    padding-top: 14.5rem;
    @include under1200{
      padding-top: 8rem;
    }

    h2 {
      font-size: 2.8rem;
      text-transform: uppercase;
      margin-bottom: 0;
      position: relative;
      letter-spacing: 1px;

      .brown-text {
        font-family: 'ralewaylight', sans-serif;
      }

      .blue-text {
        font-family: 'ralewaybold', sans-serif;
        font-weight: bold;
      }

      &:before {
        content: "";
        position: absolute;
        display: block;
        width: 100rem;
        height: 2.1rem;
        background-color: $brown;
        right: calc(100% + 2rem);
        top: 0.7rem;

        @include under1200{
          right: 100%;
        }
      }
    }
  }

  .image-container {
    position: relative;

    h3 {
      position: absolute;
      margin-bottom: 0;
      text-transform: uppercase;
      color: #fff;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      margin-top: 20px;
      padding-top: 15px;
      z-index: 5;
      top: 0;
      left: 0;
      letter-spacing: 1px;
      font-family: 'ralewaybold', sans-serif;

      &::before {
        content: "";
        width: 120%;
        height: 50px;
        display: block;
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        border-bottom: 25px solid #960200;
        border-top: 25px solid #960200;
        border-right: 25px solid transparent;
      }

      &.demarage_travaux::before {
        border-top-color: #960200 !important;
        border-bottom-color: #960200 !important;
      }

      &.operation_terminiee::before {
        border-top-color: #82d173 !important;
        border-bottom-color: #82d173 !important;
      }

      &.livraison::before {
        border-top-color: #6e2594 !important;
        border-bottom-color: #6e2594 !important;
      }

      &.operation_en_cours::before {
        border-top-color: #50c5b7 !important;
        border-bottom-color: #50c5b7 !important;
      }

      &.grand_lancement::before {
        border-top-color: #edae49 !important;
        border-bottom-color: #edae49 !important;
      }
    }

    .share-icon {
      position: absolute;
      top: 30px;
      right: 30px;
    }
  }

  .left-part {
    margin-top: 10.5rem;
    @include under1200{
      margin-top: 6rem;
    }

    .image-container {
      margin-right: 4rem;
      margin-bottom: 10rem;
      & > img {
        width: 100%;
      }
      @include underContainerMedium{
        margin-right: 2rem;
      }
      @include under1200{
        margin-right: 0rem;
      }
      @include under768{
        margin-bottom: 2rem;
      }
    }

    .share-icon {
      @include under1200{
        right: 25px;
        width: 25px;
      }
    }
  }

  .right-part {
    .image-container {
      margin-left: 4rem;
      text-align: right;

      & > img {
        width: 100%;
      }
      @include underContainerMedium{
        margin-left: 2rem;
      }
      @include under1200{
        margin-left: 0;
      }
      @include under768{
        margin-bottom: 2rem;
      }

      h3 {
        &::before {
          border-bottom: 25px solid #6e2594;
          border-top: 25px solid #6e2594;
        }
      }

      .share-icon {
        position: absolute;
        top: 30px;
        right: 25px;
        @include under1200{
          top: 26px;
          right: 30px;
          width: 25px;
        }
      }
    }

    .program-data {
      margin-top: 18rem;
      margin-bottom: 11rem;
    }
  }

  .program-data {
    h6 {
      font-size: 2.2rem;
      margin-bottom: 3px;
      line-height: 1;
      font-family: 'ralewaylight', sans-serif;
      text-transform: capitalize !important;

      & * {
        font-size: 2.2rem !important;
        margin-bottom: 3px !important;
        line-height: 1 !important;
        font-family: 'ralewaylight', sans-serif !important;
        text-transform: capitalize !important;
      }
      strong {
        font-family: 'ralewaybold', sans-serif;
      }

      & b {
        font-family: 'ralewaybold', sans-serif !important;
      }
    }

    h4 {
      text-transform: uppercase;
      font-size: 2.8rem;
      line-height: 1;
      margin-bottom: 3rem;
      letter-spacing: 2px;
      font-family: 'ralewaybold', sans-serif;

      sup {
        top: -1rem;
        font-size: 60%;
      }
    }

    h3.lien {
      letter-spacing: 1px;
      font-family: 'ralewaylight', sans-serif;
      font-size: 1.8rem;
      margin-bottom: 3.5rem;
      text-decoration: underline;

      a {
        font-family: 'ralewaylight', sans-serif;
        font-size: 1.8rem;
        color: $blue;
      }
    }

    h5 {
      font-size: 1.8rem;
      text-transform: uppercase;
      margin-bottom: 3.5rem;
      letter-spacing: 1px;
      font-family: 'ralewaylight', sans-serif;

      strong {
        letter-spacing: 1px;
        font-family: 'ralewaybold', sans-serif;

        span {
          text-decoration: underline;
        }
      }
    }

    .program-btn-link {
      border: 2px solid $brown;
      color: $brown;
      text-transform: uppercase;
      text-align: center;
      display: inline-block;
      width: 29rem;
      height: 6.5rem;
      font-size: 2rem;
      padding-top: 1.6rem;

      &:hover {
        background-color: $brown;
        color: #fff;
        text-decoration: none;
      }
    }

    &.data-for-same-program {
      text-align: center;
      margin-bottom: 4rem;
      margin-top: 0;
    }
  }

  .blue-button {
    margin: 9rem auto 10rem;
    width: 385px;
    height: 6rem;
    background-image: url("/images/button_bg.jpg");
    background-position: center;
    background-size: 100% 100%;
    text-align: center;
    font-size: 2.2rem;
    text-transform: uppercase;
    color: #fff;
    padding-top: 14px;
    position: relative;

    &:hover {
      text-decoration: none;
      background-image: url("/images/button_bg_reverse.jpg");
    }

    &::after{
      content: "";
      display: block;
      position: absolute;
      width: 135rem;
      height: 106rem;
      border-bottom: 1px solid #ded7ca;
      border-left: 1px solid #ded7ca;
      transform: rotateZ(-32deg);
      transform-origin: bottom left;
      left: 51rem;
      bottom: 26rem;
      z-index: -1;
    }
  }
}

#about {
  font-family: 'ralewaymedium', sans-serif;
  background-color: #f2f2f2;
  padding-bottom: 100px;
  padding-top: 9rem;
  position: relative;
  z-index: 1;

  @include under1200{
    padding-top: 8rem;
  }

  h3 {
    font-family: 'ralewaylight', sans-serif;
    padding-bottom: 10rem;
    text-transform: uppercase;
    font-size: 2.8rem;
    position: relative;
    margin-bottom: 0;
    letter-spacing: 1px;
    @include under1200{
      padding-bottom: 6rem;
    }

    strong {
      font-family: 'ralewaybold', sans-serif;
    }

    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 100rem;
      height: 2.1rem;
      background-color: $blue;
      left: calc(100% + 2rem);
      top: 0.7rem;

      @include under1200{
        left: calc(100% + 1rem);
      }
    }
  }

  p {
    text-align: justify;
    font-size: 1.8rem;
    margin-bottom: 3.3rem;
    line-height: 1.8;
  }

  .homepage-button {
    @include under768{
      display: block;
      margin: 0 auto 3rem;
    }
  }

  .right-side {
    position: relative;
    padding: 0;
    &::after{
      content: "";
      display: block;
      position: absolute;
      width: 135rem;
      height: 110rem;
      border: 1px solid #ded7ca;
      transform: rotateZ(-32deg);
      transform-origin: bottom right;
      right: 90%;
      bottom: -1rem;
      z-index: -1;
    }
  }
}


#metiers {
  font-family: 'ralewaymedium', sans-serif;
  padding-top: 14rem;
  padding-bottom: 15rem;
  position: relative;
  z-index: 1;

  @include under1200{
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  h2 {
    font-size: 2.8rem;
    text-transform: uppercase;
    margin-bottom: 10rem;
    position: relative;
    font-family: 'ralewaylight', sans-serif;
    letter-spacing: 1px;
    strong {
    }

    @include under1200{
      padding-left: 15px;
      margin-bottom: 6rem;
    }

    .brown-text {
      font-family: 'ralewaylight', sans-serif;
    }

    .blue-text {
      font-family: 'ralewaybold', sans-serif;
    }

    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 100rem;
      height: 2.1rem;
      background-color: $brown;
      top: 0.7rem;
      right: calc(100% + 2rem);

      @include under1200{
        right: 100%;
      }
    }
  }

  .imobilier-text {
    display: inline-block;
    text-transform: uppercase;
    font-size: 2.4rem;
    line-height: 1.3;
    vertical-align: top;
    margin-left: 3.5rem;
    margin-top: 1.3rem;

    .brown-text {
      font-family: 'ralewaybold', sans-serif;
    }

    .blue-text {
      font-family: 'ralewaylight', sans-serif;
    }
  }

  .first {
    @include under992{
      text-align: center !important;
    }

    @include under768{
      margin-bottom: 4rem;
    }
  }

  .second {
    @include under992{
      text-align: center !important;
    }
  }

  .third {
    @include under992{
      margin-top: 4rem;
      text-align: center !important;
    }
  }
}

#actualites {
  font-family: 'ralewaymedium', sans-serif;
  background-color: $brown;
  padding-bottom: 8rem;
  padding-top: 8.5rem;
  position: relative;
  z-index: 1;

  @include under1200{
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .image-part {
    @include under768{
      width: 100%;
      margin-bottom: 2rem;
    }
  }

  h3 {
    padding-bottom: 0;
    margin-bottom: 4rem;
    text-transform: uppercase;
    font-size: 2.8rem;
    position: relative;
    color: #fff;
    letter-spacing: 1px;
    font-family: 'ralewaylight', sans-serif;

    strong {
      font-family: 'ralewaybold', sans-serif;
    }

    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 100rem;
      height: 2.1rem;
      background-color: #fff;
      left: calc(100% + 2rem);
      top: 0.7rem;

      @include under1200{
        left: calc(100% + 1rem);
      }
    }
  }

  h4 {
    font-size: 2.5rem;
    line-height: 1;
    margin-bottom: 0;

    b {
      font-size: 3.5rem;
    }
  }


  h5 {
    font-size: 2.4rem;
    margin-bottom: 3rem;
  }

  p {
    font-size: 1.8rem !important;
    padding: 0 !important;
    line-height: 1.4 !important;
    color: #fff !important;

    & * {
      font-size: 1.8rem !important;
      padding: 0 !important;
      line-height: 1.4 !important;
      color: #fff !important;
    }
  }

  .homepage-button {
    margin-top: 3.5rem;
  }
}

.homepage-button {
  font-family: 'ralewaymedium', sans-serif;
  width: 36rem;
  height: 9rem;
  font-size: 2rem;
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  text-align: center;
  padding-top: 2.6rem;
  text-decoration: none;
  max-width: 100%;

  &.blue-btn {
    color: $blue;
    border-color: $blue;

    &:hover {
      background-color: $blue;
      color: #fff;
    }
  }

  &.brown-btn {
    color: $brown;
    border-color: $brown;

    &:hover {
      background-color: $brown;
      color: #fff;
    }
  }

  &.white-btn {
    color: #fff;
    border-color: #fff;

    &:hover {
      background-color: #fff;
      color: $brown;
    }
  }
}